import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { Breakpoints } from './Demo/dictionaries';

type TextAlign = 'left' | 'center';

interface Props {
  headline: string;
  tagline?: string;
  children: React.ReactNode;
  textAlign?: TextAlign;
  background?: string;
  id?: string;
}

const Container = styled.section`
  margin-top: 30px;
  background: ${({ background }: Pick<Props, 'background'>) =>
    background ? background : 'transparent'};

  @media (min-width: ${Breakpoints.md}px) {
    margin-top: 110px;
  }
`;

const Header = styled.header`
  margin: 0 auto 45px auto;
  text-align: ${({ textAlign }: Pick<Props, 'textAlign'>) =>
    textAlign ? textAlign : 'center'};
`;

const Headline = styled.h3`
  margin: 0;
  font-size: 36px;
  line-height: 1;
`;

const Tagline = styled.p`
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 200;
  letter-spacing: 0.8px;
  color: ${colors.gray};
  max-width: 400px;
  margin: 15px 0 0 0;
`;

const Section: React.FC<Props> = ({
  headline,
  tagline,
  children,
  textAlign,
  background,
  id,
}) => {
  return (
    <Container id={id || ''} background={background}>
      <Header textAlign={textAlign}>
        <Headline>{headline}</Headline>
        {tagline && <Tagline>{tagline}</Tagline>}
      </Header>
      {children}
    </Container>
  );
};

export default Section;
