import { enumToArray, fetchAPI } from '../../../utils/utils';
import {
  Coins,
  Currencies,
  ChartPeriods,
  CurrencySymbols,
} from './dictionaries';

const getRates = async () => {
  try {
    return await fetchAPI({
      method: 'get',
      url: 'https://min-api.cryptocompare.com/data/pricemulti',
      queryParams: {
        fsyms: enumToArray(Coins)
          .map(([, coin]) => coin)
          .join(','),
        tsyms: enumToArray(Currencies).map(([, currency]) => currency),
      },
    });
  } catch (e) {
    console.error(e);
  }
};

const getCharts = async ({
  period,
  coinA,
  coinB,
  currency,
}: {
  period: ChartPeriods;
  coinA: Coins;
  coinB: Coins;
  currency: Currencies;
}) => {
  const params = {
    [ChartPeriods.Day]: { aggregate: 1, limit: 24, api: 'histohour' },
    [ChartPeriods.Week]: { aggregate: 24, limit: 7, api: 'histohour' },
    [ChartPeriods.Month]: { aggregate: 1, limit: 30, api: 'histoday' },
    [ChartPeriods.ThreeMonth]: { aggregate: 1, limit: 90, api: 'histoday' },
    [ChartPeriods.Year]: { aggregate: 1, limit: 365, api: 'histoday' },
  };

  const periodBasedParams = params[period];

  const getArgs = (coin: Coins) => ({
    method: 'get',
    url: `https://min-api.cryptocompare.com/data/v2/${periodBasedParams.api}`,
    queryParams: {
      aggregate: periodBasedParams.aggregate,
      limit: periodBasedParams.limit,
      fsym: coin,
      tsym: currency,
    },
  });

  try {
    const [coinAResults, coinBResults] = await Promise.all([
      fetchAPI(getArgs(coinA)),
      fetchAPI(getArgs(coinB)),
    ]);

    return coinAResults.Data.Data.map(
      (coinAResult: { time: number; close: number }, i: number) => {
        return {
          dateTime: coinAResult.time,
          [coinA]: coinAResult.close,
          currency: CurrencySymbols[currency],
          [coinB]: coinBResults.Data.Data[i].close,
        };
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export { getRates, getCharts };
