import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';

import ShakingHandIcon from './shaking-hand-icon.svg';
import { colors } from '../../../../styles/colors';

import 'react-typist/dist/Typist.css';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.grayShark};
  border-radius: 20px;
  font-size: 18px;
  line-height: 23px;
  font-family: 'source code pro', sans-serif;
  font-weight: normal;
  height: 100%;

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    background: url(${ShakingHandIcon}) no-repeat center center;
    background-size: cover;
    left: 10%;
    top: -20%;
    z-index: 1;
  }
`;

const Text = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  text-align: center;

  a {
    font-weight: 600;
    color: ${colors.orange};
    text-decoration: none;
  }
`;

const steps = [
  { text: <span>Hi there. I&apos;m Cerebro Wallet.</span>, length: 29 },
  {
    text: <span>Try the charts. Compare the two different currencies.</span>,
    length: 53,
  },
  {
    text: <span>What&apos;s your local currency? Replace USD.</span>,
    length: 40,
  },
  {
    text: <span>You can scroll the accounts. I support a lot of coins.</span>,
    length: 54,
  },
  {
    text: (
      <span>
        All the functions and others are waiting for you in the{' '}
        <a
          href="https://app.cerebrowallet.com"
          target="_blank"
          rel="noreferrer"
        >
          wallet
        </a>
        .
      </span>
    ),
    length: 62,
  },
];

const Helper: React.FC = () => {
  return (
    <Container>
      <Text>
        <Typist>
          {steps.map((step, i) => (
            <span key={i}>
              {step.text}
              <Typist.Backspace
                count={i < steps.length - 1 ? step.length : 0}
                delay={3000}
              />
            </span>
          ))}
        </Typist>
      </Text>
    </Container>
  );
};

export default Helper;
