import React from 'react';

import { Container, Button, Title } from './styled';
import { ChartPeriods } from '../../dictionaries';
import { enumToArray } from '../../../../../utils/utils';
import { useDemoContext } from '../../DemoContextProvider';

const TimeFilter: React.FC = () => {
  const { chartFilters, setChartFilters } = useDemoContext();

  return (
    <Container>
      <Title>Chart</Title>
      {enumToArray(ChartPeriods).map(([key, value]) => (
        <Button
          key={key}
          type="button"
          onClick={() => {
            if (value !== chartFilters.period) {
              setChartFilters({
                ...chartFilters,
                period: value as ChartPeriods,
              });
            }
          }}
          active={value === chartFilters.period}
        >
          {value}
        </Button>
      ))}
    </Container>
  );
};

export default TimeFilter;
