import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Grid, Cell } from 'styled-css-grid';

import { colors } from '../../styles/colors';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'BasierCircle';
    src: url('assets/fonts/basiercircleRegular') format('woff2'),
         url('assets/fonts/basiercircleRegular') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'BasierCircle';
    src: url('assets/fonts/basiercircleSemibold') format('woff2'),
         url('assets/fonts/basiercircleSemibold') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  body {
    font: 400 18px BasierCircle, sans-serif;
    line-height: 28px;
    background-color: ${colors.black};
    color: ${colors.white};
  }
  
  a {
    color: ${colors.white};
    text-decoration: none;
  }
  
  button {
    &:focus {
      outline: none;
    }
  }
  
  * {
    box-sizing: border-box;
  }
`;

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Grid gap={'20px'}>
        <GlobalStyles />
        {children}
      </Grid>
    </Container>
  );
};

export default Layout;
