import styled from 'styled-components';

import { colors } from '../../../../../styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button`
  color: ${(props: ButtonProps) => (props.active ? colors.black : colors.gray)};
  font-weight: ${(props: ButtonProps) => (props.active ? 600 : 'normal')};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border: 0;
  background: none;
  padding: 0;
  margin-left: 0.625rem;
  cursor: ${(props: ButtonProps) => (props.active ? 'default' : 'pointer')};
  transition: color 0.15s ease-in;

  &:focus {
    outline: none;
  }
`;

export const Title = styled.h3`
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${colors.gray};
  margin: 0 0.9375rem 0 0;
`;
