import React, { useEffect, useState } from 'react';
import { Cell, Grid } from 'styled-css-grid';
import styled from 'styled-components';

import { fetchAPI } from '../../utils/utils';

import BlogPost from './BlogPost';
import Space from '../shared/Space';
import { colors } from '../../styles/colors';

export interface IBlogPost {
  thumbnail: string;
  pubDate: string;
  title: string;
  link: string;
}

const ReadMoreLink = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  margin-top: 40px;
  color: ${colors.orange};
  text-decoration: none;
`;

const Container = styled(Grid)`
  justify-items: center;
`;

const BlogPosts: React.FC = () => {
  const [posts, setPosts] = useState<IBlogPost[] | null>(null);

  useEffect(() => {
    fetchAPI({
      url:
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/cerebrowallet',
      method: 'get',
    })
      .then(({ items }) => setPosts(items.slice(0, 3)))
      .catch(() => setPosts([]));
  }, []);

  if (posts === null) {
    return null;
  }

  return (
    <>
      <Container gap="20px" columns="repeat(auto-fit,minmax(230px,1fr))">
        {posts.map((post) => (
          <BlogPost post={post} key={post.link} />
        ))}
      </Container>
      <ReadMoreLink href="https://medium.com/cerebrowallet">
        Read more ›
      </ReadMoreLink>
      <Space height={90} />
    </>
  );
};

export default BlogPosts;
