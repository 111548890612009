import styled from 'styled-components';
import { animated } from 'react-spring';

import { Statuses } from './NewsletterEmailInput';
import { colors } from '../../../styles/colors';

export const Container = styled.div`
  position: relative;
  margin-bottom: 0.625rem;
`;

export const Input = styled.input`
  display: block;
  background: ${colors.white};
  border: 0;
  border-radius: 10px;
  padding: 13px 20px;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 37px;
  color: ${colors.grayShark};
  resize: none;
  transition: border-color 0.15s ease-in;

  &:focus {
    outline: none;
  }
`;

export const Button = styled(animated.button)`
  position: absolute;
  top: 4px;
  right: 4px;
  border: 0;
  background: ${colors.grayShark};
  color: ${colors.white};
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 0.375rem;
  padding: 0;
  transition: background-color 0.2s ease-in;

  &:focus {
    outline: none;
  }

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;

    & > div {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }
`;

interface SubscribeStatusProps {
  status: Statuses | null;
}

export const SubscribeStatus = styled(animated.div)`
  position: absolute;
  top: 100%;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-top: 4px;
  color: ${(props: SubscribeStatusProps) => {
    if (props.status === Statuses.Success) {
      return colors.green;
    }

    if (props.status === Statuses.Fail) {
      return colors.red;
    }

    return colors.white;
  }};
`;
