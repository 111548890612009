import { Coins } from './components/home/Demo/dictionaries';
import { coinColors } from './styles/colors';

export interface Config {
  coins: {
    [id: string]: {
      name: string;
      abbr: Coins;
      color: string;
    };
  };
  supportEmail: string;
}

const config: Config = {
  coins: {
    BTC: {
      name: 'Bitcoin',
      abbr: Coins.BTC,
      color: coinColors[Coins.BTC],
    },
    STX: {
      name: 'Blockstack',
      abbr: Coins.STX,
      color: coinColors[Coins.STX],
    },
    ETH: {
      name: 'Ethereum',
      abbr: Coins.ETH,
      color: coinColors[Coins.ETH],
    },
    BNB: {
      name: 'Binance Coin',
      abbr: Coins.BNB,
      color: coinColors[Coins.BNB],
    },
    LTC: {
      name: 'Litecoin',
      abbr: Coins.LTC,
      color: coinColors[Coins.LTC],
    },
    ZEC: {
      name: 'Zcash',
      abbr: Coins.ZEC,
      color: coinColors[Coins.ZEC],
    },
  },
  supportEmail: 'support@cerebrowallet.com',
};

export { config };
