import React from 'react';
import styled from 'styled-components';
import { Grid, Cell } from 'styled-css-grid';

import { colors } from '../styles/colors';
import BlockstackIcon from '../../content/assets/images/blockstack-icon.inline.svg';
import PlayIcon from '../../content/assets/images/play-icon.inline.svg';
import MultiCurrency from '../../content/assets/images/multi-currency.svg';
import ForBlockstackers from '../../content/assets/images/for-blockstackers.svg';
import OpenSource from '../../content/assets/images/open-source.svg';
import BuiltInExchange from '../../content/assets/images/built-in-exchange.svg';
import { Breakpoints } from '../components/home/Demo/dictionaries';
import { scrollTo } from '../components/layout/HeaderMenu';

import Layout from '../components/layout/Layout';
import Header from '../components/layout/Header';
import Seo from '../components/shared/Seo';
import IconButton from '../components/shared/IconButton';
import Demo from '../components/home/Demo/Demo';
import Section from '../components/home/Section';
import Feature from '../components/home/Feature';
import Collapsible from '../components/shared/Collapsible';
import Footer from '../components/layout/Footer';
import BlogPosts from '../components/home/BlogPosts';

const Intro = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 100px 20px 146px;
  }
`;

const Headline = styled.h1`
  align-self: center;
  margin: 0 0 14px 0;
  max-width: 590px;
  font-size: 36px;
  line-height: 44px;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 54px;
    line-height: 58px;
    margin: 0 0 20px 0;
  }
`;

const Tagline = styled.h2`
  align-self: center;
  font-weight: 200;
  letter-spacing: 1px;
  margin: 0 0 25px 0;
  color: ${colors.gray};
  max-width: 400px;

  font-size: 21px;
  line-height: 32px;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const IntroActions = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  & > * {
    margin-bottom: 15px;

    @media (min-width: ${Breakpoints.md}px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 340px;
    flex-direction: row;
  }
`;

const Overview = styled.div`
  border-radius: 20px;
  background: ${colors.grayExtraLight};

  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Index: React.FC = () => {
  return (
    <Layout>
      <Seo title="Cerebro Wallet" />
      <Cell width={12}>
        <Header />
      </Cell>
      <Cell width={12}>
        <Intro>
          <Headline>Cerebro Wallet for users owned internet</Headline>
          <Tagline>
            Perhaps the safest way to manage multiple cryptocurrency assets
          </Tagline>
          <IntroActions>
            <IconButton link="https://app.cerebrowallet.com" target="_blank">
              <BlockstackIcon />
              Get Started
            </IconButton>
            <IconButton onClick={() => scrollTo('#overview')}>
              <PlayIcon />
              Overview
            </IconButton>
          </IntroActions>
        </Intro>
      </Cell>
      <Cell width={12}>
        <Demo />
      </Cell>
      <Cell width={12}>
        <Section
          headline="Features"
          id="features"
          tagline="Browser-based and adaptive for mobile. All required features in one wallet."
        >
          <Grid gap="20px" columns="repeat(auto-fit,minmax(280px,1fr))">
            <Cell>
              <Feature
                headline="Free and open-source"
                tagline={
                  <>
                    Fully open and transparent wallet. Our code is{' '}
                    <a
                      href="https://github.com/cerebrowallet/cerebro-wallet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      open-source
                    </a>{' '}
                    and trusted by blockchain professionals.
                  </>
                }
                picture={OpenSource}
              />
            </Cell>
            <Cell>
              <Feature
                headline="Multi-currency"
                tagline="Receive, send and store the most popular coins, like Bitcoin, Ethereum, Litecoin, Binance Coin and lots more."
                picture={MultiCurrency}
              />
            </Cell>
            <Cell>
              <Feature
                headline="For blockstackers"
                tagline="Interact with the blockchain via Cerebro Wallet. Manage your Stacks tokens and use it into Clarity contracts."
                picture={ForBlockstackers}
                comingSoon
              />
            </Cell>
            <Cell>
              <Feature
                headline="Built-in exchange"
                tagline="In-app exchange by reliable partners, it allows converting coins to each other most intuitively and easily."
                picture={BuiltInExchange}
                comingSoon
              />
            </Cell>
          </Grid>
        </Section>
      </Cell>
      <Cell width={12}>
        <Section
          headline="Overview"
          id="overview"
          tagline="Discover what’s possible in Cerebro Wallet. Watch the short video."
        >
          <Overview>
            <iframe
              src="https://www.youtube.com/embed/N-OVLKaQMvo"
              frameBorder="0"
              allowFullScreen
            />
          </Overview>
        </Section>
      </Cell>
      <Cell width={12}>
        <Section headline="Frequently asked questions" textAlign="left">
          <Collapsible
            title="Why should I use Cerebro Wallet?"
            content="We have great news. A new app for Blockstack community has been created. Meet the Cerebro Wallet. We have developed a multi-currency wallet with a built-in exchange system and other features → this is the main difference from a default Stacks wallet."
          />
          <Collapsible
            title="Supported cryptocurrencies"
            content={
              <>
                We started with Bitcoin. We plan to add other currencies after
                testing the beta version of the wallet. Get your favorite coin
                added to Cerebro →{' '}
                <a
                  href="https://github.com/cerebrowallet/cerebro-wallet/issues"
                  target="_blank"
                  rel="noreferrer"
                >
                  Suggest a coin
                </a>
                .
              </>
            }
          />
          <Collapsible
            title="How does Cerebro Wallet work?"
            content="Cerebro works in a web-browser on your laptop or mobile device. It is based on Blockstack technology, which allows you to own your own data. Your data and your assets are stored in Blockstack only. That is why you will need a Blockstack ID to log in."
          />
          <Collapsible
            title="How does Cerebro Wallet store and use my data?"
            content="We do not store user data. The wallet reads the required data from the Blockstack storage at the time of authorization and use. You can send us your email for our newsletter. This email will not be matched to your Blockstack ID. We respect your privacy."
          />
          <Collapsible
            title="What is Blockstack and Blockstack ID?"
            content={
              <>
                «Blockstack builds protocols and developer tools designed to
                enable a fair and open Internet that returns digital rights to
                developers and consumers. Led by some of the world&apos;s
                foremost experts on distributed systems, Blockstack allows users
                to own their own data that they can take with them from app to
                app in the ecosystem, along with their Blockstack ID that
                eliminates the need for password-based logins. The end result is
                privacy, security, and freedom.» –{' '}
                <a
                  href="https://blockstack.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blockstack.org
                </a>
              </>
            }
          />
          <Collapsible
            title="Can I transfer from my existing wallet like Exodus, Blockchain.com, etc.?"
            content="Yes. You can import a private key or mnemonic from any wallet. Legacy or new private key format is not important. Wallet will try to understand everything."
          />
          <Collapsible
            title="Does Cerebro Wallet have fees for sending or receiving?"
            content="Cerebro Wallet doesn’t charge you any commision. The only time a user may have to pay is a blockchain commission (network fee) for performing a transaction."
          />
          <Collapsible
            title="How do I contact the Cerebro Wallet team?"
            content={
              <>
                We are open for communication via email →{' '}
                <a href="mailto:support@cerebrowallet.com">
                  support@cerebrowallet.com.
                </a>{' '}
                And you can create an issue on Github →{' '}
                <a
                  href="https://github.com/cerebrowallet/cerebro-wallet/issues/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://github.com/cerebrowallet/cerebro-wallet/issues
                </a>
                . Stay tuned!
              </>
            }
          />
        </Section>
      </Cell>
      <Cell width={12}>
        <Section
          headline="Blog"
          tagline="Keep up to date with in-depth content from the makers of Cerebro."
        >
          <BlogPosts />
        </Section>
      </Cell>
      <Cell width={12}>
        <Footer />
      </Cell>
    </Layout>
  );
};

export default Index;
