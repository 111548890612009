import React from 'react';
import styled from 'styled-components';

interface Props {
  height: number;
}

const Container = styled.div`
  height: ${({ height }: Props) => `${height}px`};
`;

const Space: React.FC<Props> = ({ height }) => <Container height={height} />;

export default Space;
