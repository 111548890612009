import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

import { Container } from './styled';
import { config } from '../../../../../config';
import { colors } from '../../../../../styles/colors';
import { useDemoContext } from '../../DemoContextProvider';

import CustomTooltip from './Tooltip/Tooltip';
import Loader from '../../../../shared/Loader/Loader';

const ChartContainer: React.FC = () => {
  const { chartFilters, chartData } = useDemoContext();

  if (chartData === null) {
    return <Loader />;
  }

  return (
    <Container>
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{
            top: 80,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <Tooltip
            cursor={false}
            content={<CustomTooltip />}
            isAnimationActive={false}
          />
          <Area
            type="monotone"
            dataKey={chartFilters.coinA}
            stroke={'none'}
            fill={config.coins[chartFilters.coinA].color}
            strokeWidth={2}
            fillOpacity={0.2}
            activeDot={{
              fill: colors.white,
              strokeOpacity: 0.2,
              stroke: config.coins[chartFilters.coinA].color,
              strokeWidth: 1,
              r: 3,
            }}
          />
          <Area
            type="monotone"
            dataKey={chartFilters.coinB}
            stroke={'none'}
            fill={config.coins[chartFilters.coinB].color}
            strokeWidth={2}
            fillOpacity={0.2}
            activeDot={{
              fill: colors.white,
              strokeOpacity: 0.2,
              stroke: config.coins[chartFilters.coinB].color,
              strokeWidth: 1,
              r: 3,
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default ChartContainer;
