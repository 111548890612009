/**
 * Converts the given enum to a map of the keys to the values.
 * @param enumeration The enum to convert to a map.
 */
export function enumToMap(enumeration: any): Map<string, string | number> {
  const map = new Map<string, string | number>();
  for (const key in enumeration) {
    //TypeScript does not allow enum keys to be numeric
    if (!isNaN(Number(key))) continue;

    const val = enumeration[key] as string | number;

    //TypeScript does not allow enum value to be null or undefined
    if (val !== undefined && val !== null) map.set(key, val);
  }

  return map;
}

export function enumToArray(enumeration: any): [string, string | number][] {
  return Array.from(enumToMap(enumeration).entries());
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

export function isObject(object: any): boolean {
  return typeof object === 'object' && object !== null;
}

export function getQueryString(queryParams: {
  [name: string]: string | number;
}): string {
  return Object.keys(queryParams)
    .map(
      (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k])
    )
    .join('&');
}

export async function fetchAPI({
  method,
  url,
  body,
  headers,
  queryParams,
}: any): Promise<any> {
  if (isObject(queryParams)) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + getQueryString(queryParams);
  }

  const res = await fetch(url, {
    method,
    headers,
    body:
      headers && headers['content-type'] === 'application/x-www-form-urlencoded'
        ? body
        : JSON.stringify(body),
  });

  return res.json();
}

export const round = (value: number, decimals = 2) => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
};
