import React from 'react';
import styled from 'styled-components';
import { Cell } from 'styled-css-grid';

import { colors } from '../../styles/colors';
import dayjs from 'dayjs';

import { IBlogPost } from './BlogPosts';

interface Props {
  post: IBlogPost;
}

const Container = styled(Cell)`
  display: flex;
  text-decoration: none;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  width: 300px;
`;

const Link = styled.a`
  display: block;
  overflow: hidden;
  border-radius: 20px;
`;

const Image = styled.div`
  background: url(${({ imgUrl }: { imgUrl: string }) => imgUrl}) no-repeat
    center center;
  background-size: cover;
  height: 180px;
`;

const Content = styled.div`
  background: ${colors.white};
  padding: 20px 20px 25px;
  flex-grow: 1;
`;

const Date = styled.span`
  font-size: 12px;
  line-height: 1;
  color: ${colors.gray};
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
`;

const BlogPost: React.FC<Props> = ({
  post: { link, pubDate, thumbnail, title },
}) => {
  return (
    <Container>
      <Link href={link} target="_blanc">
        <Image imgUrl={thumbnail} />
        <Content>
          <Date>{dayjs(pubDate).format('MMM D, YYYY')}</Date>
          <Title>{title}</Title>
        </Content>
      </Link>
    </Container>
  );
};

export default BlogPost;
