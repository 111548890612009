import React from 'react';

import {
  AccountItem,
  AccountsContainer,
  Header,
  ScrollbarTrackY,
  Title,
} from './styled';
import { CurrencySymbols } from '../dictionaries';
import { round } from '../../../../utils/utils';

import { useDemoContext } from '../DemoContextProvider';

import Scrollbar from '../../../shared/Scrollbar/Scrollbar';
import CurrencyIcon from '../../../shared/CurrencyIcon/CurrencyIcon';

const Accounts: React.FC = () => {
  const { accounts, rates, currency } = useDemoContext();

  return (
    <AccountsContainer>
      <Header>
        <Title>Accounts</Title>
      </Header>
      <Scrollbar TrackY={ScrollbarTrackY}>
        {accounts.map((account) => (
          <AccountItem
            key={account.id}
            icon={<CurrencyIcon coin={account.coin} size="lg" />}
            link="https://app.cerebrowallet.com"
            text={account.name}
            descText={`${account.balance} ${account.coin} · ${
              CurrencySymbols[currency]
            }${
              rates
                ? round(account.balance * rates[account.coin][currency])
                : ''
            }`}
          />
        ))}
      </Scrollbar>
    </AccountsContainer>
  );
};

export default Accounts;
