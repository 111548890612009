import { Coins } from './dictionaries';
import { Account } from './models';

const accounts: Account[] = [
  {
    id: 'btc',
    name: 'Bitcoin',
    coin: Coins.BTC,
    balance: 0.1,
    descText: '',
  },
  {
    id: 'stx',
    name: 'Blockstack',
    coin: Coins.STX,
    balance: 40219,
    descText: '',
  },
  {
    id: 'eth',
    name: 'Ethereum',
    coin: Coins.ETH,
    balance: 0.52,
    descText: '',
  },
  {
    id: 'bnb',
    name: 'Binance Coin',
    coin: Coins.BNB,
    balance: 250,
    descText: '',
  },
  {
    id: 'ltc',
    name: 'Litecoin',
    coin: Coins.LTC,
    balance: 10,
    descText: '',
  },
  {
    id: 'zec',
    name: 'Zcash',
    coin: Coins.ZEC,
    balance: 31,
    descText: '',
  },
];

export { accounts };
