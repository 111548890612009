import styled from 'styled-components';

import { Coins } from '../../home/Demo/dictionaries';
import { coinColors } from '../../../styles/colors';

import btcIcon from './icons/btc.svg';
import stxIcon from './icons/stx.svg';
import ethereum from './icons/ethereum.svg';
import binance from './icons/binance.svg';
import litecoin from './icons/litecoin.svg';
import zcash from './icons/zcash.svg';

interface IconProps {
  coin: Coins;
  size?: string;
}

export const Icon = styled.i`
  position: relative;
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;

  &:before {
    display: block;
    position: absolute;
    border-radius: 100%;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    content: '';
  }

  ${(props: IconProps) =>
    props.coin === Coins.BTC &&
    `
    background-image: url(${btcIcon});    
    
    &:before {
      background: ${coinColors[Coins.BTC]};
    }
  `}
  
  ${(props: IconProps) =>
    props.coin === Coins.STX &&
    `
    background-image: url(${stxIcon});    
    
    &:before {
      background: ${coinColors[Coins.STX]};
    }
  `}
  
  ${(props: IconProps) =>
    props.coin === Coins.ETH &&
    `
    background-image: url(${ethereum});    
    
    &:before {
      background: ${coinColors[Coins.ETH]};
    }
  `}
  
  ${(props: IconProps) =>
    props.coin === Coins.BNB &&
    `
    background-image: url(${binance});    
    
    &:before {
      background: ${coinColors[Coins.BNB]};
    }
  `}
  
  ${(props: IconProps) =>
    props.coin === Coins.LTC &&
    `
    background-image: url(${litecoin});    
    
    &:before {
      background: ${coinColors[Coins.LTC]};
    }
  `}
  
  ${(props: IconProps) =>
    props.coin === Coins.ZEC &&
    `
    background-image: url(${zcash});    
    
    &:before {
      background: ${coinColors[Coins.ZEC]};
    }
  `}
  
  ${(props: IconProps) =>
    props.size === 'lg' &&
    `
    width: 2.25rem;
    height: 2.25rem;
  `}
  
  ${(props: IconProps) =>
    props.size === 'xl' &&
    `
    width: 4rem;
    height: 4rem;
  `}
` as any;
