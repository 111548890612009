import React from 'react';
import { Grid, Cell } from 'styled-css-grid';

import { DemoContextProvider } from './DemoContextProvider';
import { useWindowSize } from '../../../utils/hooks';
import { Breakpoints } from './dictionaries';

import TotalBalance from './TotalBalance/TotalBalance';
import Helper from './Helper/Helper';
import Accounts from './Accounts/Accounts';
import Charts from './Charts/Charts';

const Demo: React.FC = () => {
  const windowSize = useWindowSize();

  if (windowSize.width < Breakpoints.md) {
    return null;
  }

  return (
    <DemoContextProvider>
      <Grid gap={'20px'}>
        <Cell width={4}>
          <TotalBalance />
        </Cell>
        <Cell width={8}>
          <Helper />
        </Cell>
        <Cell width={4}>
          <Accounts />
        </Cell>
        <Cell width={8}>
          <Charts />
        </Cell>
      </Grid>
    </DemoContextProvider>
  );
};

export default Demo;
