import styled from 'styled-components';
import { Link } from 'gatsby';

import AccountButton from './AccountButton/AccountButton';
import { TrackYEl } from '../../../shared/Scrollbar/styled';
import { Breakpoints } from '../dictionaries';
import { colors } from '../../../../styles/colors';

export const AccountsContainer = styled.section`
  position: relative;
  background: ${colors.white};
  border-radius: 1.25rem;
  grid-area: accounts;
  display: flex;
  flex-direction: column;
  padding: 1.5625rem 0.9375rem 0.9375rem 0.9375rem;
  height: 100%;

  &:after {
    position: absolute;
    display: none;
    content: '';
    height: 2.625rem;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: 0.9375rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      ${colors.white} 100%
    );
  }

  @media (min-width: ${Breakpoints.xl}px) {
    &:after {
      display: block;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8125rem 0 0.9375rem;
  margin-bottom: 1.25rem;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${colors.gray};
`;

export const AddAccountButton = styled(Link)`
  position: relative;
  display: block;
  width: 2.25rem;
  height: 1.5625rem;
  border-radius: 1.6875rem;
  background: ${colors.grayLight};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.15s ease-in;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.gray};
    width: 0.75rem;
    height: 0.75rem;
    transition: color 0.15s ease-in;
  }

  &:hover {
    background: ${colors.blackLight};

    svg {
      color: ${colors.white};
    }
  }

  &:active {
    background: ${colors.blackLight};

    svg {
      color: ${colors.white};
    }
  }
`;

export const AccountItem = styled(AccountButton)`
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 0.8125rem 1rem 0.8125rem 0.625rem;
  border-radius: 2.375rem;
  transition: background-color 0.15s ease-in;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${colors.grayExtraLight};
  }

  @media (min-width: ${Breakpoints.xl}px) {
    &:last-child {
      margin-bottom: 1.5625rem;
    }
  }

  &:focus {
    outline: none;
  }

  &.active {
    background: ${colors.grayExtraLight};

    &:hover {
      background: ${colors.grayExtraLight};
    }
  }
`;

export const ScrollbarTrackY = styled(TrackYEl)`
  right: -0.7rem;
`;
