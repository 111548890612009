import React from 'react';

import { AnyObject } from '../../models';

import { Text, Description, Icon, Title, ExternalLink } from './styled';

interface Props {
  link: string;
  icon: React.ReactElement<any>;
  text: string;
  descText: string;
  className?: string;
  children?: React.ReactNode;
  style?: any;
  state?: AnyObject;
}

const AccountButton: React.FC<Props> = ({
  link,
  text,
  descText,
  icon,
  children,
  className,
  style,
}) => {
  if (!link) {
    return null;
  }

  return (
    <ExternalLink href={link} className={className} style={style}>
      <Icon>{icon}</Icon>
      <Text>
        <Title>{text}</Title>
        <Description>{descText}</Description>
      </Text>
      {children}
    </ExternalLink>
  );
};

export default AccountButton;
