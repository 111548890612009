import React from 'react';

import { Coins } from '../dictionaries';
import { ChartFilters } from '../models';

import { Container, Header } from './styled';

import TimeFilter from './TimeFilter/TimeFilter';
import CoinsFilter from './CoinsFilter/CoinsFilter';
import ChartContainer from './ChartContainer/ChartContainer';

const Charts: React.FC = () => {
  return (
    <Container>
      <Header>
        <TimeFilter />
        <CoinsFilter />
      </Header>
      <ChartContainer />
    </Container>
  );
};

Charts.defaultProps = {
  coinA: Coins.BTC,
};

export default Charts;
