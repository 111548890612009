import React, { useMemo } from 'react';

import { Currencies } from '../dictionaries';
import { enumToArray, round } from '../../../../utils/utils';
import { useDemoContext } from '../DemoContextProvider';

import { Container, Amount, Title } from './styled';
import WhiteDropDownMenu from '../../../shared/WhiteDropDownMenu/WhiteDropDownMenu';

const TotalBalance: React.FC = () => {
  const { currency, changeCurrency, accounts, rates } = useDemoContext();

  const totalBalance = useMemo(() => {
    return accounts.reduce((balance, account) => {
      let acc = balance;
      acc += rates ? account.balance * rates[account.coin][currency] : 0;
      return acc;
    }, 0);
  }, [currency, rates]);

  return (
    <Container>
      <Title>
        Total balance
        <WhiteDropDownMenu
          selected={currency}
          menuItems={enumToArray(Currencies).map(([key, value]) => ({
            name: key,
            id: value,
          }))}
          onChange={(newCurrency: Currencies) => changeCurrency(newCurrency)}
        />
      </Title>
      <Amount>{round(totalBalance)}</Amount>
    </Container>
  );
};

export default TotalBalance;
