import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    line-height: 1;
  }
`;
