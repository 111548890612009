import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu, ChevronLeft } from 'react-feather';
import { Link } from 'gatsby';

import { colors } from '../../styles/colors';
import ChevronDownIcon from '../../../content/assets/images/chevron-down-icon.svg';
import ArrowTopRightIcon from '../../../content/assets/images/arrow-top-right-icon.svg';
import { Breakpoints } from '../home/Demo/dictionaries';
import { useWindowSize } from '../../utils/hooks';

const MenuLink = styled(Link)`
  position: relative;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:after {
    position: absolute;
    left: 0;
    top: 140%;
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: ${colors.orange};
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    color: ${colors.orange};

    &:after {
      opacity: 1;
    }
  }
`;

const AnchorLink = styled.button`
  position: relative;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border: 0;
  background: none;
  color: white;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    width: 8px;
    height: 5px;
    background: url(${ChevronDownIcon}) no-repeat center center;
    left: 50%;
    top: 130%;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    color: ${colors.orange};

    &:after {
      opacity: 1;
    }
  }
`;

const ExternalLink = styled.a`
  position: relative;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border: 0;
  background: none;
  color: white;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    top: 0;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.2s ease-in-out;
    background: url(${ArrowTopRightIcon}) no-repeat center center;
    background-size: cover;
    right: -25px;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

const WalletLink = styled.a`
  position: relative;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border: 0;
  background: none;
  color: ${colors.orange};

  &:after {
    display: none;
  }

  &:hover {
    color: ${colors.orangeDark};
  }
`;

const MenuContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: ${colors.black};
  display: ${({ opened }: { opened: boolean }) => (opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px;

  & > * {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 80px;
    padding: 0;

    & > * {
      margin-bottom: 0;
    }
  }
`;

export const MobileTrigger = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: 0;
  color: ${colors.white};
  padding: 0;

  @media (min-width: ${Breakpoints.md}px) {
    display: none;
  }
`;

export const CloseMobileMenu = styled.button`
  position: absolute;
  top: 26px;
  right: 26px;
  width: 24px;
  height: 24px;
  background: none;
  border: 0;
  color: ${colors.white};
  padding: 0;
  margin: 0;

  @media (min-width: ${Breakpoints.md}px) {
    display: none;
  }
`;

export const scrollTo = (element: string) => {
  document.querySelector(element)?.scrollIntoView({
    behavior: 'smooth',
  });
};

const HeaderMenu: React.FC = () => {
  const [opened, toggleMobileMenu] = useState(false);

  return (
    <>
      <MenuContainer opened={opened}>
        <CloseMobileMenu type="button" onClick={() => toggleMobileMenu(false)}>
          <ChevronLeft />
        </CloseMobileMenu>
        <AnchorLink onClick={() => scrollTo('#features')}>Features</AnchorLink>
        <AnchorLink onClick={() => scrollTo('#overview')}>Overview</AnchorLink>
        {/*<AnchorLink to={'/'}>Assets</AnchorLink>*/}
        {/*<MenuLink to={'/'}>Donut</MenuLink>*/}
        <ExternalLink href={'https://wiki.cerebrowallet.com'} target="_blank">
          Wiki
        </ExternalLink>
        <ExternalLink href={'https://medium.com/cerebrowallet'} target="_blank">
          Blog
        </ExternalLink>
        <WalletLink href={'https://app.cerebrowallet.com'} target="_blank">
          Wallet ›
        </WalletLink>
      </MenuContainer>
      <MobileTrigger type="button" onClick={() => toggleMobileMenu(!opened)}>
        <Menu />
      </MobileTrigger>
    </>
  );
};

export default HeaderMenu;
