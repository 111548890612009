import { useEffect, useState } from 'react';

export function useOnClickOutside(
  ref: any,
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export interface WindowDimensions {
  width: number;
  height: number;
}

export function useWindowSize(): WindowDimensions {
  function getSize(): WindowDimensions {
    return {
      width:
        typeof window !== 'undefined' && window.innerWidth
          ? window.innerWidth
          : 0,
      height:
        typeof window !== 'undefined' && window.innerHeight
          ? window.innerHeight
          : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined' && window.addEventListener) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return windowSize;
}
