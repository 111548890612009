import React from 'react';

import { Coins } from '../../dictionaries';
import { config } from '../../../../../config';
import { enumToArray } from '../../../../../utils/utils';
import { useDemoContext } from '../../DemoContextProvider';

import { Container } from './styled';

import WhiteDropDownMenu, {
  Option,
} from '../../../../shared/WhiteDropDownMenu/WhiteDropDownMenu';
import CoinValue from '../CoinValue/CoinValue';

const options = enumToArray(Coins).map(([key, value]) => ({
  name: `${config.coins[value].name} ${key}`,
  id: value,
}));

const CoinsFilter: React.FC = () => {
  const { chartFilters, setChartFilters } = useDemoContext();

  return (
    <Container>
      <WhiteDropDownMenu
        selected={chartFilters.coinA}
        menuItems={options}
        onChange={(value: Coins) => {
          if (chartFilters.coinA !== value) {
            setChartFilters({
              ...chartFilters,
              coinA: value,
            });
          }
        }}
        valueRenderer={(value: Option) => <CoinValue coin={value.id} />}
        position="right"
      />
      <WhiteDropDownMenu
        selected={chartFilters.coinB}
        menuItems={options}
        onChange={(value: Coins) => {
          if (chartFilters.coinB !== value) {
            setChartFilters({
              ...chartFilters,
              coinB: value,
            });
          }
        }}
        valueRenderer={(value: Option) => <CoinValue coin={value.id} />}
        position="right"
      />
    </Container>
  );
};

export default CoinsFilter;
