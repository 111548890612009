export enum Coins {
  BTC = 'BTC',
  STX = 'STX',
  ETH = 'ETH',
  LTC = 'LTC',
  ZEC = 'ZEC',
  BNB = 'BNB',
}

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  CNY = 'CNY',
  GBP = 'GBP',
  RUB = 'RUB',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  PKR = 'PKR',
  SEK = 'SEK',
}

export enum CurrencySymbols {
  BTC = 'BTC',
  USD = '$',
  EUR = '€',
  CNY = '¥',
  GBP = '£',
  RUB = '₽',
  HKD = 'HK$',
  INR = '₹',
  JPY = '¥',
  PKR = '₨',
  SEK = 'kr',
}

export enum KeyTypes {
  Mnemonic,
  PrivateKey,
  WIF,
}

export enum AddressTypes {
  SegWit,
  P2PKH,
}

export enum ChartPeriods {
  Day = '1D',
  Week = '1W',
  Month = '1M',
  ThreeMonth = '3M',
  Year = '1Y',
}

export enum Breakpoints {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1500,
}
