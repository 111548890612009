import React, { useEffect, useState } from 'react';
import { config, useTransition } from 'react-spring';

import { Button, Container, Input, SubscribeStatus } from './styled';
import Loader from '../../../components/shared/Loader/Loader';

export enum Statuses {
  Success = 'success',
  Fail = 'fail',
  InProgress = 'inProgress',
}

const NewsletterEmailInput: React.FC = () => {
  const [status, setStatus] = useState<Statuses | null>(null);
  const [emailDraft, setEmailDraft] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(emailDraft));
  }, [emailDraft]);

  const transitions = useTransition(
    valid || status === Statuses.InProgress,
    null,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.stiff,
    }
  );

  const statusTransition = useTransition(!!status, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
  });

  async function subscribe() {
    setStatus(Statuses.InProgress);

    try {
      const response = await fetch(
        'https://api.sendgrid.com/v3/marketing/contacts',
        {
          method: 'put',
          headers: {
            Authorization: `Bearer ${process.env.SENDGRID_API_KEY}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            list_ids: [process.env.SENDGRID_LIST_ID],
            contacts: [{ email: emailDraft }],
          }),
        }
      );

      if (response.status !== 200 && response.status !== 202) {
        throw new Error();
      }

      setStatus(Statuses.Success);
    } catch (e) {
      setStatus(Statuses.Fail);
    }

    setTimeout(() => {
      setStatus(null);
      setEmailDraft('');
    }, 5000);
  }

  return (
    <Container>
      <Input
        placeholder="Enter your e-mail address"
        value={emailDraft}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmailDraft(e.target.value)
        }
        disabled={status === Statuses.InProgress}
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            subscribe();
          }
        }}
      />
      {transitions.map(
        ({ key, props, item }) =>
          item && (
            <Button key={key} style={props} onClick={() => subscribe()}>
              {status === Statuses.InProgress ? <Loader /> : '→'}
            </Button>
          )
      )}
      {statusTransition.map(
        ({ key, props, item }) =>
          item && (
            <SubscribeStatus style={props} key={key} status={status}>
              {status === Statuses.Success && "Thank you! You're subscribed."}
              {status === Statuses.Fail && 'Whoops, try again!'}
            </SubscribeStatus>
          )
      )}
    </Container>
  );
};

export default NewsletterEmailInput;
