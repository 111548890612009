import React from 'react';
import styled from 'styled-components';
import { Grid, Cell } from 'styled-css-grid';

import Twitter from '../../../content/assets/images/social/twitter.inline.svg';
import Discord from '../../../content/assets/images/social/discord.inline.svg';
import BlockstackForum from '../../../content/assets/images/social/blockstack-forum.inline.svg';
import Medium from '../../../content/assets/images/social/medium.inline.svg';
import ProductHunt from '../../../content/assets/images/social/product-hunt.inline.svg';
import Youtube from '../../../content/assets/images/social/youtube.inline.svg';

import { colors } from '../../styles/colors';
import { scrollTo } from './HeaderMenu';

import NewsletterEmailInput from './NewsletterEmailInput/NewsletterEmailInput';

const Container = styled.footer`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.gray};
`;

const FooterMenu = styled(Grid)`
  margin-bottom: 80px;
`;

const MenuHeader = styled.h5`
  font-size: 12px;
  line-height: 1;
  margin: 0 0 25px 0;
  font-weight: normal;
  color: ${colors.gray};
`;

const MenuItem = styled.a`
  display: block;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: ${colors.white};
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${colors.orange};
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: justify;
  color: ${colors.gray};
  margin-bottom: 55px;
`;

const SocialLink = styled.a`
  text-decoration: none;

  svg {
    fill: ${colors.white};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg {
      fill: ${colors.orange};
    }
  }
`;

const FooterCopy = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.gray};
  margin-bottom: 30px;
`;

const Copy = styled.div``;

const CopyLink = styled.a`
  color: ${colors.gray};
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  margin-left: 25px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: ${colors.orange};
  }
`;

const Footer: React.FC = () => (
  <Container>
    <FooterMenu gap="20px" columns="repeat(auto-fit,minmax(150px,1fr))">
      <Cell>
        <MenuHeader>Cerebro</MenuHeader>
        <MenuItem onClick={() => scrollTo('#features')}>Features</MenuItem>
        <MenuItem onClick={() => scrollTo('#overview')}>Overview</MenuItem>
        <MenuItem href="https://wiki.cerebrowallet.com">Wiki</MenuItem>
        <MenuItem href="https://medium.com/cerebrowallet">Blog</MenuItem>
      </Cell>
      {/*<Cell>*/}
      {/*  <MenuHeader>Assets</MenuHeader>*/}
      {/*  <MenuItem href="/">Bitcoin</MenuItem>*/}
      {/*  <MenuItem href="/">Ethereum</MenuItem>*/}
      {/*  <MenuItem href="/">Stacks</MenuItem>*/}
      {/*  <MenuItem href="/">Others</MenuItem>*/}
      {/*</Cell>*/}
      <Cell>
        <MenuHeader>Links</MenuHeader>
        <MenuItem href="mailto:support@cerebrowallet.com">
          Email support
        </MenuItem>
        <MenuItem href="https://discord.gg/M6QmytH">Discord</MenuItem>
        <MenuItem href="https://github.com/cerebrowallet">Github</MenuItem>
        {/*<MenuItem href="/">Press Inquiries</MenuItem>*/}
      </Cell>
      <Cell>
        <MenuHeader>Newsletter</MenuHeader>
        <NewsletterEmailInput />
        <MenuHeader>Social</MenuHeader>
        <Social>
          <SocialLink href="https://twitter.com/cerebrowallet">
            <Twitter />
          </SocialLink>
          <SocialLink href="https://discord.gg/M6QmytH">
            <Discord />
          </SocialLink>
          <SocialLink href="#">
            <BlockstackForum />
          </SocialLink>
          <SocialLink href="https://medium.com/cerebrowallet">
            <Medium />
          </SocialLink>
          <SocialLink href="#">
            <ProductHunt />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/channel/UCqehl6FtHewOPM81E2vpKiQ/">
            <Youtube />
          </SocialLink>
        </Social>
      </Cell>
    </FooterMenu>
    <Disclaimer>
      Cerebro Wallet is the software platform only that uses public data from
      blockchain and explorer APIs. The platform does not create, does not store
      cryptocurrency inside the app or servers and does not conduct any
      independent verification or substantial analysis of any blockchain assets,
      digital currency, cryptocurrency or related funds. When you receive funds
      or send them out, or perform exchanges, you’re interacting directly with
      the blockchain. You are fully and solely responsible for evaluating your
      investments, for determining whether you will exchange blockchain assets
      on the basis of your own, and for all your decisions regarding whether to
      exchange blockchain assets with Cerebro Wallet. Any investment in
      blockchain assets is associated with the risk of losing part or all of
      your investments. The value of the blockchain assets that you exchange is
      subject to market and other investment risks. We disclaim any obligation
      for the reliability of any blockchain assets, digital currency,
      cryptocurrency or related funds.
    </Disclaimer>
    <FooterCopy>
      <Copy>Cerebro Wallet &copy; 2020</Copy>
      <div>
        <CopyLink href="mailto:support@cerebrowallet.com">
          Send feedback
        </CopyLink>
        <CopyLink
          href="https://github.com/cerebrowallet/cerebro-wallet/issues"
          target="_blank"
        >
          Create issue
        </CopyLink>
        <CopyLink href="https://wiki.cerebrowallet.com/beta" target="_blank">
          For beta testers
        </CopyLink>
      </div>
    </FooterCopy>
  </Container>
);

export default Footer;
