import React from 'react';
import styled, { keyframes } from 'styled-components';

import CerebroIcon from '../../../content/assets/images/cerebro-icon.svg';
import HeaderMenu from './HeaderMenu';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  height: auto;
`;

const Logo = styled.div`
  width: 28px;
  height: 28px;
  background: url(${CerebroIcon}) no-repeat center center;
  animation: ${rotate} 1.4s;
  animation-timing-function: cubic-bezier(0.8, 0, 0.5, 1);
`;

const Header: React.FC = () => (
  <Container>
    <Logo />
    <HeaderMenu />
  </Container>
);

export default Header;
