import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/colors';

const styles = `
  font-size: 21px;
  line-height: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${colors.white};
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${colors.orange};

    svg {
      fill: ${colors.orange};
    }
  }

  svg {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    transition: fill 0.2s ease-in-out;
    fill: ${colors.white};
  }
`;

const Button = styled.button`
  background: none;
  border: 0;

  ${styles}
`;

const Link = styled.a`
  ${styles}
`;

interface Props {
  onClick?: () => void;
  link?: string;
  target?: string;
  children: React.ReactNode;
}

const IconButton: React.FC<Props> = ({ onClick, link, children, target }) => {
  if (link) {
    return (
      <Link href={link} target={target}>
        {children}
      </Link>
    );
  }

  return (
    <Button type="button" onClick={onClick}>
      {children}
    </Button>
  );
};

export default IconButton;
