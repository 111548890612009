import React, { useEffect, useState } from 'react';

import { Account, ChartData, ChartFilters, ExchangeRates } from './models';
import { ChartPeriods, Coins, Currencies } from './dictionaries';
import { accounts } from './data';
import { getCharts, getRates } from './api';

export interface DemoContext {
  currency: Currencies;
  chartData: ChartData | null;
  chartFilters: ChartFilters;
  changeCurrency: (newCurrency: Currencies) => void;
  setChartFilters: (filters: ChartFilters) => void;
  accounts: Account[];
  rates: ExchangeRates | null;
}

const initialChartFilters = {
  period: ChartPeriods.Month,
  coinA: Coins.BTC,
  coinB: Coins.ETH,
};

export const DemoContext = React.createContext<DemoContext>({
  currency: Currencies.USD,
  accounts: [],
  chartData: null,
  chartFilters: initialChartFilters,
  rates: null,
  setChartFilters: (filters: ChartFilters) => {
    // void
  },
  changeCurrency: () => {
    // void
  },
});

export const DemoContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currency, setCurrency] = useState(Currencies.USD);
  const [rates, setRates] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [chartFilters, setChartFilters] = useState<ChartFilters>(
    initialChartFilters
  );

  useEffect(() => {
    getRates().then((rates) => setRates(rates));
    getCharts({ currency, ...chartFilters }).then((data) => setChartData(data));
  }, []);

  useEffect(() => {
    getCharts({ currency, ...chartFilters }).then((data) => setChartData(data));
  }, [chartFilters.coinA, chartFilters.coinB, chartFilters.period]);

  const changeCurrency = (newCurrency: Currencies) => setCurrency(newCurrency);

  const values = React.useMemo(
    () => ({
      currency,
      chartData,
      chartFilters,
      setChartFilters,
      changeCurrency,
      accounts,
      rates,
    }),
    [currency, chartData, chartFilters, rates]
  );

  return <DemoContext.Provider value={values}>{children}</DemoContext.Provider>;
};

export const useDemoContext = () => {
  const context = React.useContext(DemoContext);

  if (context === undefined) {
    throw new Error(
      '`useDemoContext` hook must be used within a `DemoContextProvider` component'
    );
  }

  return context;
};
