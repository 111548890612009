import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { Breakpoints } from './Demo/dictionaries';

const Container = styled.div`
  position: relative;
  border-radius: 20px;
  background: ${colors.grayExtraLight};
  padding: 15px;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 25px;
  }

  ${({ comingSoon }: { comingSoon?: boolean }) =>
    comingSoon &&
    `
    &:before {
      position: absolute;
      right: 15px;
      top: 15px;
      display: block;
      content: 'coming soon';
      font-size: 14px;
      line-height: 1;
      color: ${colors.gray};
      font-weight: 600;
    }
  `}
`;

const Image = styled.img`
  margin-bottom: 25px;
`;

const Headline = styled.h4`
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 15px 0;
  color: ${colors.black};

  @media (min-width: ${Breakpoints.md}px) {
    margin: 0 0 20px 0;
  }
`;

const Tagline = styled.p`
  font-size: 18px;
  font-weight: 200;
  line-height: 28px;
  color: ${colors.gray};
  margin: 0 0 10px 0;

  a {
    color: ${colors.black};
    text-decoration: none;
  }
`;

interface Props {
  picture: string;
  headline: string;
  tagline: string | React.ReactNode;
  comingSoon?: boolean;
}

const Feature: React.FC<Props> = ({
  headline,
  tagline,
  picture,
  comingSoon,
}) => {
  return (
    <Container comingSoon={comingSoon}>
      <Image src={picture} />
      <Headline>{headline}</Headline>
      <Tagline>{tagline}</Tagline>
    </Container>
  );
};

export default Feature;
